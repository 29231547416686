:root {
    /* Spacings: Usable for margins, paddings, gaps */
    --spacing-xsmall: 1px;
    --spacing-small: 2px;
    --spacing-medium: 10px;
    --spacing-large: 20px;
    --spacing-xlarge: 40px;
    --spacing-xxlarge: 100px;

    /* Sizes: Usable for bars and buttons */
    --size-small: 20px;
    --size-medium: 40px;
    --size-large: 100px;
    --size-xlarge: 200px;
    --size-xxlarge: 600px;
    --size-xxxlarge: 1000px;

    /* Radius: Usable for any elements  */
    --radius-xsmall: 2px;
    --radius-small: 4px;
    --radius-medium: 8px;
    --radius-large: 14px;
    --radius-xlarge: 20px;
    --radius-max: 50%;

    /* Borders usable for any elements  */
    --border-small: 1px;
    --border-medium: 2px;
    --border-large: 3px;

    --color-background-0: #FFFFFF;
    --color-background-1: #F3F3F3;
    --color-background-3: #444444;

    --color-text-0: #000000;
    --color-text-2: #AAAAAA;
    --color-text-3: #FFFFFF;
    --color-text-4: deepskyblue;


    /* Regular couples of colors usable for notifications */
    --color-background-success: deepskyblue;
    --color-text-success: #222222;
    --color-background-warning: #FFA500;
    --color-text-warning: #222222;
    --color-background-error: deeppink;
    --color-text-error: #FFFFFF;
    --color-background-information: #FFFFFF;
    --color-text-information: #222222;
    --color-background-action: #FFFFFF;
    --color-text-action: #222222;
    --color-background-cancel: #222222;
    --color-text-cancel: #FFFFFF;

    /* Responsive breakpoints */
    --breakpoint-screen-width: 800px;


}


body {

}

body.darkTheme {

}
