.desktop {
    display: initial;
}

.mobile {
    display: none !important;
}

@media (max-width: 1000px) {
    .desktop,
    .optional {
        display: none !important;
    }

    .mobile {
        display: initial !important;
    }
}
