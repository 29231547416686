@import url('./styles-variables.scss');
@import url('./styles-overrides.scss');
@import url('./styles-breakpoints.scss');

body {
    font-family: Arial, Helvetica, sans-serif;
}

section {

    margin-bottom: 50px;
    font-size: large;
}

header {
    font-size: xx-large;
    margin-bottom: 10px;
}


input,
textarea,
select {
    flex: 1;
    font-size: large;
    border: none;

    text-align: inherit;
    background-color: transparent;
}

input[type=button] {
    background-color: deepskyblue !important;
    padding: var(--spacing-medium);
    text-align: center;
    min-width: var(--size-xlarge);
    cursor: pointer;
}

textarea {

    height: 100px;
    text-align: start;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 2px;  // 2px width because 1px may be hidden by the border of the window.
    height: 0px; // 0px for horizontal scrollback (hidden)
}

::-webkit-scrollbar-thumb {
    border-left: 2px solid black;
}

::-webkit-scrollbar-button {
    background-color: transparent;
}


.disabled {
    opacity: 40%;
    pointer-events: none;
}

.pulsing-slow {
    animation: pulse 1s;
}

.pulsing-fast {
    animation: pulse 0.4s;
}

.pulsing-slow,
.pulsing-fast {
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}
